import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { filter, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { exists } from '../helpers/common_helpers';
import { AccessTokenInterface, CourierUserInterface, Role } from '../models/api-models';
import { SystemService } from './system.service';
import { LocalStorageService } from '../local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  TOKEN_KEY = 'TOKEN';
  BASE_URL = `${environment.apiUrl}/${environment.apiVersion}/`;
  ENDPOINT_USERS = 'CourierUsers/';

  private isAuthenticated = new BehaviorSubject(false);
  isAuthenticated$ = this.isAuthenticated.asObservable();
  private isAdmin = new BehaviorSubject(true);
  isAdmin$ = this.isAdmin.asObservable();
  private isCashier = new BehaviorSubject(true);
  isCashier$ = this.isCashier.asObservable();

  private currentUser: BehaviorSubject<CourierUserInterface>;

  constructor(
    private http: HttpClient,
    private systemService: SystemService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    if (localStorage.getItem(this.TOKEN_KEY)) {
      const tokenString = localStorage.getItem(this.TOKEN_KEY);
      if (tokenString) {
        const token = JSON.parse(tokenString);
        this.setCurrentUser(token);
        this.autoLogout(token);
      }
    } else {
      console.log('TOKEN NOT FOUND');
      this.router.navigate([]);
    }
  }

  get currentUser$(): Observable<CourierUserInterface> {
    if (this.currentUser) {
      return this.currentUser.asObservable().pipe(shareReplay(1));
    }

    const tokenString = localStorage.getItem(this.TOKEN_KEY);
    if (!tokenString) return of({});

    const user = this.getUserByToken(JSON.parse(tokenString));
    return user.pipe(shareReplay(1));
  }

  public login(email: string, password: string) {
    return this.systemService.courier$.pipe(
      filter(exists),
      switchMap(courier => {
        const filter = ``;
        return this.http.post(`${this.BASE_URL}${this.ENDPOINT_USERS}login`, {
          email,
          password,
          realm: courier.realm
        }) as Observable<AccessTokenInterface>;
      })
    );
  }

  public triggerPasswordReset(email: string) {
    return this.systemService.courier$.pipe(
      filter(exists),
      switchMap(courier => {
        return this.http.post(`${this.BASE_URL}${this.ENDPOINT_USERS}reset`, {
          email,
          courierId: courier.id,
          redirectUrl: courier.hostingUrl?.replace('app', 'admin')
        });
      })
    );
  }

  public setCurrentUser(token: AccessTokenInterface) {
    // console.log('SETTING TOKEN');
    localStorage.setItem(this.TOKEN_KEY, JSON.stringify(token));
    const user = this.getUserByToken(token);
    user
      .pipe(
        take(1),
        tap((user: CourierUserInterface) => {
          // console.log(user);
          this.currentUser = new BehaviorSubject(user);
          this.isAdmin.next(this.isRole('Super Administrator', user.roles));
          this.isAdmin.next(this.isRole('Administrator', user.roles));
          this.isCashier.next(this.isRole('Cashier', user.roles));
          this.isAuthenticated.next(true);
        })
      )
      .subscribe(
        user => {
          // console.log(user);
          // this.localStorageService.clear();
        },
        error => {
          console.log('Ohhh No, Your Token Expired');
          alert('Ohhh No, Your Token Expired. Please login to continue.');
          // this.localStorageService.clear();
          this.logout();
        }
      );
    this.autoLogout(token);
  }

  isRole(roleName: string, roles?: Role[]): boolean {
    if (!roles || roles?.length === 0) return false;
    for (const role of roles) {
      if (role.name === roleName) return true;
    }
    return false;
  }

  public getUserByToken(token: AccessTokenInterface): Observable<CourierUserInterface> {
    const filter = {
      include: ['roles']
    };
    return this.http.get(
      `${this.BASE_URL}${this.ENDPOINT_USERS}${token.userId}?access_token=${token.id}&filter=${JSON.stringify(filter)}`
    );
  }

  public getUserById(id: string): Observable<CourierUserInterface> {
    const filter = {
      include: ['roles']
    };
    return this.http.get(`${this.BASE_URL}${this.ENDPOINT_USERS}${id}?filter=${JSON.stringify(filter)}`);
  }

  public logout() {
    localStorage.clear();
    this.localStorageService.clear();
    this.isAuthenticated.next(false);
    this.router.navigate(['/auth']);
    this.systemService.clearCourier();
  }

  private autoLogout(token: AccessTokenInterface): void {
    const now = new Date();
    const created = token.created;
    const ttl = token.ttl || 1209600;
    const logoutDate = new Date();
    logoutDate.setSeconds(logoutDate.getSeconds() + ttl);
    if (now >= logoutDate) {
      this.logout();
    }
  }

  public getAccessToken(): string {
    const tokenString = localStorage.getItem(this.TOKEN_KEY);
    if (!tokenString) return '';

    const token: AccessTokenInterface = JSON.parse(tokenString);
    return token.id || '';
  }

  isTokenExpired() {
    const tokenString = localStorage.getItem(this.TOKEN_KEY);
    if (!tokenString) return true;

    const token: AccessTokenInterface = JSON.parse(tokenString);
    const now = new Date();
    let expiryDate = new Date(token.created);
    expiryDate.setSeconds(expiryDate.getSeconds() + token.ttl);
    return now > expiryDate;
  }
}
